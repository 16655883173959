import {getUrlParams} from 'src/app/utils.js';
import {HttpClient} from '@angular/common/http';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import {Injectable} from '@angular/core';
import {ApiData} from '../model/api-data.model';
import {ApiFunctionName} from '../enum/api-function-name.enum';
import {LanguageService} from './language.service';
import {ApiOrder} from '../model/api.order.model';
import {ApiOnlinePayment} from '../model/api.online-payment.model';

@Injectable()
export class ApiService {
  public static readonly SUBDOMAIN = 'menuluxwebapi';
  public static readonly API_BASE_URL = `https://${ApiService.SUBDOMAIN}.azurewebsites.net/api`;
  //public static readonly SUBDOMAIN = 'menuluxcoreapi2';
  //public static readonly API_BASE_URL = `https://${ApiService.SUBDOMAIN}.azurewebsites.net/api`;
  //public static readonly SUBDOMAIN = 'predicommcoreapi';
  //public static readonly API_BASE_URL = `https://${ApiService.SUBDOMAIN}.azurewebsites.net/api`;

  private static urlParams = getUrlParams() as any;
  private static hostname = window.location.hostname;
  private static webUrl = ApiService.urlParams.weburl || ApiService.hostname;
  static customerId = ApiService.urlParams.customerid;

  private dataSubjects = new Map<ApiFunctionName, ReplaySubject<ApiData>>();

  constructor(private http: HttpClient, private languageService: LanguageService) {
    const apiFunctionNames = Object.keys(ApiFunctionName);

    // Create data subjects
    for (const fn of apiFunctionNames) {
      const subject = new ReplaySubject<ApiData>();
      this.dataSubjects.set(fn as any, subject);
    }

    for (const fn of apiFunctionNames) {
      this.callApiFunction(fn as any).subscribe(apiData => {
          ApiService.customerId = apiData.CustomerID;

          this.dataSubjects.get(fn as any).next(apiData);
      });
    }

    return;
  }

  private callApiFunction(apiFunctionName: ApiFunctionName): Observable<ApiData> {
    let code = this.languageService.currentLanguage;
    const uri = `${ApiService.API_BASE_URL}/WebsiteAPI/${apiFunctionName}?weburl=${ApiService.webUrl}&language=${code}`;
    return this.http.get(uri) as Observable<ApiData>;
  }

  public apiData(apiFunctionName: ApiFunctionName): Subject<ApiData> {
    return this.dataSubjects.get(apiFunctionName);
  }

  /**
   * Creates a login request and sends it.
   * @param userBundle
   * @return {Promise<any>}
   */
  public loginUser(userBundle) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `${ApiService.API_BASE_URL}/MenuluxAPI/LoginAccount`,
        type: 'POST',
        data: JSON.stringify(userBundle),
        contentType: 'application/json',
        success: resolve,
        error: reject
      });
    });
  }

  /**
   * Sends user's password to user as SMS.
   * @returns {Promise}
   * */
  public getAccountPassword(username) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `${ApiService.API_BASE_URL}/MenuluxAPI/GetAccountPassword?customerID=${ApiService.customerId}&username=${username}`,
        type: 'GET',
        contentType: "application/json;charset=utf-8",
        success: resolve,
        error: reject
      });
    });
  }

  /**
   * Gets list of cities of given country
   * @param iso ISO country code
   * @return {Promise<any>}
   */
  public getCities(iso) {
    return new Promise(((resolve, reject) => {
      $.ajax({
        url: `${ApiService.API_BASE_URL}/GeoPlaceAPI/GetCities?iso=${iso}`,
        type: 'GET',
        dataType: 'json',
        success: resolve,
        error: reject
      });
    }));
  }

  /**
   * Gets list of districts of given city
   * @param iso ISO country code
   * @param city Name of the city
   * @return {Promise<any>}
   */
  public getDistricts(iso, city) {
    return new Promise(((resolve, reject) => {
      $.ajax({
        url: `${ApiService.API_BASE_URL}/GeoPlaceAPI/GetDistricts?iso=${iso}&city=${city}`,
        type: 'GET',
        dataType: 'json',
        success: resolve,
        error: reject
      });
    }));
  }

  /**
   * Checks whether the given postcode is valid or not
   * @param iso ISO country code
   * @param city Name of the city
   * @param district Name of the district
   * @param postcode Postcode to check
   * @return {Promise<any>}
   */
  public checkPostcode(iso, city, district, postcode) {
    return new Promise(((resolve, reject) => {
      $.ajax({
        url: `${ApiService.API_BASE_URL}/GeoPlaceAPI/CheckPostCode?iso=${iso}&city=${city}&district=${district}&postcode=${postcode}`,
        type: 'GET',
        dataType: 'json',
        success: resolve,
        error: reject
      });
    }));
  }

  /**
   * @param iso ISO country code
   * @param postcode
   * @return adress
   */
  public getAdress(iso, postcode) {
    return new Promise(((resolve, reject) => {
      $.ajax({
        url: `${ApiService.API_BASE_URL}/GeoPlaceAPI/GetAddresses?iso=${iso}&postcode=${postcode}`,
        type: 'GET',
        dataType: 'json',
        success: resolve,
        error: reject
      });
    }));
  }

  /**
   * Creates a create account request and sends it.
   * @param account
   * @return {Promise<any>}
   */
  public createAccount(account) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `${ApiService.API_BASE_URL}/MenuluxAPI/PostAccount`,
        type: 'POST',
        data: JSON.stringify(account),
        contentType: "application/json;charset=utf-8",
        success: resolve,
        error: reject
      });
    });
  }

  /**
   * Retrives account data
   * @return {Promise<any>}
   */
  public getOnlinePaymentStatus(onlinePaymentID) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `${ApiService.API_BASE_URL}/OnlinePaymentAPI/GetPaymentStatus?paymentID=${onlinePaymentID}`,
        type: 'GET',
        dataType: 'json',
        success: resolve,
        error: reject
      });
    });
  }

  /**
   * Retrives account data
   * @return {Promise<any>}
   */
  public getAccount(accountId) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `${ApiService.API_BASE_URL}/AccountAPI/GetAccount?accountID=${accountId}`,
        type: 'GET',
        dataType: 'json',
        success: resolve,
        error: reject
      });
    });
  }

  /**
   * Get summary data
   * @return {Promise<any>}
   */
  public getCustomerSummary() {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `${ApiService.API_BASE_URL}/CustomerAPI/GetSummary?customerID=${ApiService.customerId}`,
        type: 'GET',
        dataType: 'json',
        success: resolve,
        error: reject
      });
    });
  }

  /**
   * Gets details of a product
   * @param productId ID of the product
   * @return {Promise<any>}
   */
  public getProduct(productId) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `${ApiService.API_BASE_URL}/ProductAPI/Get/${productId}`,
        type: 'GET',
        success: resolve,
        error: reject
      });
    });
  }

  /**
   * Updates an account
   * @param data
   * @return {Promise<any>}
   */
  public putAccount(data) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `${ApiService.API_BASE_URL}/AccountAPI/PutAccount`,
        type: 'POST',
        data: JSON.stringify(data),
        contentType: "application/json;charset=utf-8",
        success: resolve,
        error: reject
      });
    });
  }

  /**
   * Gets user accounts
   * @param accountId
   * @param lastIndex
   * @param chunkSize
   */
  public getAccountOrders(accountId, lastIndex = 0, chunkSize = 10) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `${ApiService.API_BASE_URL}/OnlineOrderAPI/GetAccountOrders?accountID=${accountId}&skip=${lastIndex}&count=${chunkSize}`,
        type: 'GET',
        dataType: 'json',
        success: resolve,
        error: reject
      });
    });
  }

  /**
   * Posts an order
   * @param order
   */
  public postOrder(order: ApiOrder) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `${ApiService.API_BASE_URL}/OnlineOrderAPI/Post`,
        type: 'POST',
        data: JSON.stringify(order),
        contentType: "application/json;charset=utf-8",
        success: resolve,
        error: reject
      });
    });
  }

  /**
   * Updates an order
   * @param order
   */
  public putOrder(order: ApiOrder) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `${ApiService.API_BASE_URL}/OnlineOrderAPI/Put`,
        type: 'PUT',
        data: JSON.stringify(order),
        contentType: "application/json;charset=utf-8",
        success: resolve,
        error: reject
      });
    });
  }

  /**
   * Gets online payment status
   * @param paymentId
   */
  public getOnlinePayment(paymentId: number){
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `${ApiService.API_BASE_URL}/OnlinePaymentAPI/GetPayment?paymentID=${paymentId}`,
        type: 'GET',
        contentType: "application/json;charset=utf-8",
        success: resolve,
        error: reject
      });
    });
  }

  /**
   * Posts an online payment
   * @param onlinePayment
   */
  public postOnlinePayment(onlinePayment: ApiOnlinePayment) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `${ApiService.API_BASE_URL}/OnlinePaymentAPI/Post`,
        type: 'POST',
        data: JSON.stringify(onlinePayment),
        contentType: "application/json;charset=utf-8",
        success: resolve,
        error: reject
      });
    });
  }

  /**
   * Posts an online payment
   * @param onlinePayment
   */
  public postOnlinePayment3D(onlinePayment: ApiOnlinePayment) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `${ApiService.API_BASE_URL}/OnlinePaymentAPI/Post3d`,
        type: 'POST',
        data: JSON.stringify(onlinePayment),
        contentType: "application/json;charset=utf-8",
        success: resolve,
        error: reject
      });
    });
  }

  /**
   * Gets gallery images with type
   * @param type
   */
  public getGalleryImages(type) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `${ApiService.API_BASE_URL}/CustomerImageAPI/Get?customerID=${ApiService.customerId}&type=${type}`,
        type: 'GET',
        contentType: 'application/json',
        success: resolve,
        error: reject
      });
    });
  }

  /**
   * Returns summary of menus of a customer
   */
  public getMenuSummary() {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `${ApiService.API_BASE_URL}/MenuAPI/GetSummary?customerID=${ApiService.customerId}`,
        type: 'GET',
        contentType: 'application/json',
        success: resolve,
        error: reject
      });
    });
  }

  /**
   * Returns menu
   * @param menuID
   */
  public getMenu(menuID) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `${ApiService.API_BASE_URL}/MenuAPI/GetMenu?menuID=${menuID}`,
        type: 'GET',
        contentType: 'application/json',
        success: resolve,
        error: reject
      });
    });
  }

  /**
   * Returns Package Setting
   * @param CustomerID
   */
  public getPackageSettings(CustomerID) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `${ApiService.API_BASE_URL}/SettingAPI/GetPackageSettings?customerID=${CustomerID}`,
        type: 'GET',
        contentType: 'application/json',
        success: resolve,
        error: reject
      });
    });
  }
}
